import * as THREE from "three";
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js'
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry'
import * as dat from 'dat.gui';
import gsap from "gsap";
import './style.css'

const gui = new dat.GUI();

const cursor = {
	x: 0,
	y: 0
};

const axesHelper = new THREE.AxesHelper();

const sizes = {
	width: window.innerWidth,
	height: window.innerHeight
};

const parameters = {
	color: 0xff0000,
	spin: () => {
		gsap.to(cube.rotation, { duration: 1, y: cube.rotation.y + Math.PI * 2 })
	}
}

window.addEventListener('dblclick', (event) => {
	if (!document.fullscreenElement) {
		canvas.requestFullscreen()
	} else {
		document.exitFullscreen()
	}
});

window.addEventListener('resize', (event) => {
	sizes.width = window.innerWidth;
	sizes.height = window.innerHeight;

	camera.aspect = sizes.width / sizes.height;
	camera.updateProjectionMatrix();

	renderer.setSize(sizes.width, sizes.height);
	renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
});

window.addEventListener('mousemove', (event) => {
	cursor.x = event.clientX / sizes.width - 0.5;
	cursor.y = - (event.clientY / sizes.height - 0.5);
});

const scene = new THREE.Scene();
// scene.add(axesHelper);

const fontLoader = new FontLoader();

// const geometry = new THREE.BufferGeometry();
// const count = 50;
// const positions = new Float32Array(count * 3 * 3);
// for (let i = 0; i < count * 3 * 3; i++) {
// 	positions[i] = (Math.random() - 0.5) * 4;
// }
// const positionsAttr = new THREE.BufferAttribute(positions, 3);
// geometry.setAttribute('position', positionsAttr);

const loadingMangaer = new THREE.LoadingManager();

loadingMangaer.onStart = () => {
	console.log('start');
}
loadingMangaer.onLoad = () => {
	console.log('finished');
}
loadingMangaer.onProgress = () => {
	console.log('progress');
}
const textureLoader = new THREE.TextureLoader(loadingMangaer);
// const checkerboard = textureLoader.load('/textures/minecraft.png');
// const colorTexture = textureLoader.load('/textures/door/color.jpg');
// const alphaTexture = textureLoader.load('/textures/door/alpha.jpg');
// const heightTexture = textureLoader.load('/textures/door/height.jpg');
// const normalTexture = textureLoader.load('/textures/door/normal.jpg');
// const ambientOcclusionTexture = textureLoader.load('/textures/door/ambientOcclusion.jpg');
// const metalnessTexture = textureLoader.load('/textures/door/metalness.jpg');
// const roughnessTexture = textureLoader.load('/textures/door/roughness.jpg');

const matcapTexture = textureLoader.load('/textures/matcaps/1.png');
// const gradientTexture = textureLoader.load('/textures/gradients/3.jpg');

// const cubeTextureLoader = new THREE.CubeTextureLoader(loadingMangaer);
// const enviromentMapTexture = cubeTextureLoader.load([
// 	'/textures/environmentMaps/0/px.jpg',
// 	'/textures/environmentMaps/0/nx.jpg',
// 	'/textures/environmentMaps/0/py.jpg',
// 	'/textures/environmentMaps/0/ny.jpg',
// 	'/textures/environmentMaps/0/pz.jpg',
// 	'/textures/environmentMaps/0/nz.jpg'
// ]);


// colorTexture.repeat.x = 2;
// colorTexture.repeat.y = 3;

// colorTexture.wrapS = THREE.MirroredRepeatWrapping;
// colorTexture.wrapT = THREE.MirroredRepeatWrapping;

// colorTexture.offset.x = 0.5;
// colorTexture.offset.y = 0.5;

// colorTexture.rotation = Math.PI * 0.25;
// colorTexture.center.x = 0.5;
// colorTexture.center.y = 0.5;

// checkerboard.generateMipmaps = false;
// checkerboard.minFilter = THREE.NearestFilter;
// checkerboard.magFilter = THREE.NearestFilter;

fontLoader.load('/fonts/helvetiker_regular.typeface.json', (font) => {
	const textGeometry = new TextGeometry('hello three.js', {
		font: font,
		size: 0.5,
		height: 0.2,
		curveSegments: 5,
		bevelEnabled: true,
		bevelThickness: 0.03,
		bevelSize: 0.02,
		bevelOffset: 0,
		bevelSegments: 4
	});
	textGeometry.center();
	// textGeometry.computeBoundingBox();
	// textGeometry.translate(
	// 	- (textGeometry.boundingBox.max.x - 0.02) * 0.5,
	// 	- (textGeometry.boundingBox.max.y - 0.02) * 0.5,
	// 	- (textGeometry.boundingBox.max.z - 0.03) * 0.5,
	// );
	const textMaterial = new THREE.MeshMatcapMaterial();
	textMaterial.matcap = matcapTexture;
	const text = new THREE.Mesh(textGeometry, textMaterial);
	scene.add(text);


	const donutGeometry = new THREE.TorusBufferGeometry(0.3, 0.2, 20, 45);
	// const donutMaterial = new THREE.MeshMatcapMaterial({ matcap: matcapTexture });

	for (let i = 0; i < 100; i++) {
		const donut = new THREE.Mesh(donutGeometry, textMaterial);

		donut.position.x = (Math.random() - 0.5) * 10;
		donut.position.y = (Math.random() - 0.5) * 10;
		donut.position.z = (Math.random() - 0.5) * 10;

		donut.rotation.x = Math.random() * Math.PI;
		donut.rotation.y = Math.random() * Math.PI;

		const scale = Math.random();
		donut.scale.set(scale, scale, scale);

		scene.add(donut);
	}

});
const material = new THREE.MeshBasicMaterial();
// material.map = colorTexture;
// material.color = new THREE.Color(0x00ff00);
// material.wireframe = true;
// material.opacity = 0.5;
// material.transparent = true;
// material.alphaMap = alphaTexture;
// material.side = THREE.DoubleSide;

// const material = new THREE.MeshNormalMaterial();
// material.flatShading = true;

// const material = new THREE.MeshMatcapMaterial();
// material.matcap = matcapTexture;

// const material = new THREE.MeshDepthMaterial();

// const material = new THREE.MeshLambertMaterial();

// const material = new THREE.MeshPhongMaterial();
// material.shininess = 100;
// material.specular = new THREE.Color(0x1188ff);

// gradientTexture.minFilter = THREE.NearestFilter;
// gradientTexture.magFilter = THREE.NearestFilter;
// gradientTexture.generateMipmaps = false;

// const material = new THREE.MeshToonMaterial();
// material.gradientMap = gradientTexture;

// const material = new THREE.MeshStandardMaterial();
// material.roughness = 0.45;
// material.metalness = 0.65;
// material.map = colorTexture;
// material.aoMap = ambientOcclusionTexture;
// material.aoMapIntensity = 1;
// material.displacementMap = heightTexture;
// material.displacementScale = 0.05;
// material.metalnessMap = metalnessTexture;
// material.roughnessMap = roughnessTexture;
// material.normalMap = normalTexture;
// material.normalScale.set(0.5, 0.5);
// material.alphaMap = alphaTexture;
// material.transparent = true;

// const material = new THREE.MeshStandardMaterial();
// material.roughness = 0.2;
// material.metalness = 0.7;
// material.envMap = enviromentMapTexture;

// const sphare = new THREE.Mesh(new THREE.SphereBufferGeometry(0.5, 64, 64), material);
// sphare.position.x = -1.5;

// const plane = new THREE.Mesh(new THREE.PlaneBufferGeometry(1, 1, 100, 100), material);

// const torus = new THREE.Mesh(new THREE.TorusBufferGeometry(0.3, 0.2, 64, 128), material);
// torus.position.x = 1.5;

const cube = new THREE.Mesh(new THREE.BoxGeometry(1,1,1), material);

// sphare.geometry.setAttribute('uv2', new THREE.BufferAttribute(sphare.geometry.attributes.uv.array, 2));
// plane.geometry.setAttribute('uv2', new THREE.BufferAttribute(plane.geometry.attributes.uv.array, 2));
// torus.geometry.setAttribute('uv2', new THREE.BufferAttribute(torus.geometry.attributes.uv.array, 2));

// scene.add(sphare, plane, torus);
// scene.add(cube);

// const ambientLight = new THREE.AmbientLight(0xffffff, 0.5);
// scene.add(ambientLight);

// const pointLight = new THREE.PointLight(0xffffff, 0.5);
// pointLight.position.x = 2;
// pointLight.position.y = 3;
// pointLight.position.z = 4;
// scene.add(pointLight);

// gui.add(cube.position, 'y').min(-3).max(3).step(0.01).name('elevation');
// gui.add(cube, 'visible');
// gui.add(material, 'wireframe');

// gui.addColor(parameters, 'color').onChange(() => {
// 	material.color.set(parameters.color);
// });

// gui.add(parameters, 'spin')

// gui.add(material, 'metalness').min(0).max(1).step(0.0001);
// gui.add(material, 'roughness').min(0).max(1).step(0.0001);
// gui.add(material, 'aoMapIntensity').min(0).max(10).step(0.0001);
// gui.add(material, 'displacementScale').min(0).max(1).step(0.0001);

const ratio = sizes.width / sizes.height;
const camera = new THREE.PerspectiveCamera(75, ratio, 0.1, 100);
// const camera = new THREE.OrthographicCamera(-1 * ratio, 1 * ratio, 1, -1, 0.1, 100);
// camera.position.x = 2;
// camera.position.y = 2;
camera.position.z = 3;

camera.lookAt(cube.position);
scene.add(camera);

const canvas = document.querySelector('canvas.webgl');

const orbit = new OrbitControls(camera, canvas);
orbit.enableDamping = true;

const renderer = new THREE.WebGLRenderer({
	canvas: canvas
});
renderer.setSize(sizes.width, sizes.height);
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

const clock = new THREE.Clock();

const tick = () => {

	const elapsedTime = clock.getElapsedTime();
	// cube.rotation.y = elapsedTime;

	// camera.position.x = Math.sin(cursor.x * Math.PI * 2) * 2;
	// camera.position.z = Math.cos(cursor.x * Math.PI * 2) * 2;
	// camera.position.y = cursor.y * 3;

	// camera.lookAt(cube.position)

	// sphare.rotation.y = 0.1 * elapsedTime;
	// plane.rotation.y = 0.1 * elapsedTime;
	// torus.rotation.y = 0.1 * elapsedTime;

	// sphare.rotation.x = 0.15 * elapsedTime;
	// plane.rotation.x = 0.15 * elapsedTime;
	// torus.rotation.x = 0.15 * elapsedTime;

	orbit.update();
	renderer.render(scene, camera);

	window.requestAnimationFrame(tick);
}

tick();